@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(../fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(../fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'), url(../fonts/Ubuntu-Regular.ttf) format('truetype');
}
